<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle()"
      label-width="150px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item prop="rightName" :label="$t('rights.rightName')">
            <el-select
              v-model="dataForm.rightId"
              :placeholder="$t('rights.rightName')"
              label="rightName"
            >
              <el-option
                v-for="item in rightsList"
                :key="item.id"
                :label="item.rightName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="waitPeriod" :label="$t('rights.waitPeriod')">
            <el-input
              v-model="dataForm.waitPeriod"
              :placeholder="$t('rights.waitPeriod')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            prop="availableCount"
            :label="$t('rights.availableCount')"
          >
            <el-input
              v-model="dataForm.availableCount"
              :placeholder="$t('rights.availableCount')"
              style="width: 80%"
            ></el-input>
            <el-tooltip
              class="item"
              effect="dark"
              content="-1:代表无限次"
              placement="right"
            >
              <i class="el-icon-warning-outline red"></i>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col v-if="dataForm.usefulType == '01'" :span="12">
          <el-form-item prop="usefulPeriod" :label="$t('rights.usefulPeriod')">
            <el-input
              v-model="dataForm.usefulPeriod"
              :placeholder="$t('rights.usefulPeriod')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-else :span="12">
          <el-form-item :label="$t('rights.usefulStartDate')">
            <el-date-picker
              v-model="usefulPeriodDate"
              :picker-options="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{
        $t('confirm')
      }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import debounce from 'lodash/debounce'
let _minTime = null
let _maxTime = null
export default {
  name: "rithsaddorupdate",
  props: {
    dataForms: {
      type: Object,
      default: {}
    },
    changeRight: {
      type: Function
    }
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        rightId: '',
        rightName: '',
        rightCode: '',
        waitPeriod: '',
        availableCount: "",
        startDate: '',
        endDate: '',
        usefulPeriod: '',
        usefulEndDate: "",
        usefulStartDate: ""
      },
      rightsList: [],
      healthRights: [],
      usefulPeriodDate: [],
      pickerOptions: {
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() <= _minTime || time.getTime() >= _maxTime
          }
        }
      }
    }
  },
  computed: {
    dataRule() {
      return {
        rightId: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        rightName: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        waitPeriod: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        availableCount: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        startDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        endDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getRightList();
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          let obj = this.dataForms.healthRightList.find(val => {
            return this.dataForm.id == val.rightId
          })
          this.dataForm = JSON.parse(JSON.stringify(obj));
        }
        this.dataForm.usefulType = this.dataForms.usefulType
        this.dataForm.usefulPeriod = this.dataForms.usefulPeriod
        this.dataForm.usefulStartDate = this.dataForms.usefulStartDate
        this.dataForm.usefulEndDate = this.dataForms.usefulEndDate
        this.usefulPeriodDate = [this.dataForms.usefulStartDate, this.dataForms.usefulEndDate]
        _minTime = new Date(this.dataForms.usefulStartDate) - 1
        _maxTime = new Date(this.dataForms.usefulEndDate) - 1
      })
    },
    getRightList() {
      this.$http.get('/health/right/list').then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.rightsList = res.data.data;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 表单提交
    dataFormSubmitHandle() {
      let inx = this.rightsList.findIndex(val => {
        return val.id === this.dataForm.rightId
      })
      if (inx > -1) {
        this.dataForm.rightName = this.rightsList[inx].rightName
        this.dataForm.rightCode = this.rightsList[inx].rightCode
      }
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        let item = JSON.parse(JSON.stringify(this.dataForm));
        if (item.usefulPeriod && (Number(item.usefulPeriod) > this.dataForms.usefulPeriod || Number(item.usefulPeriod) <= 0)) {
          this.$message.error('权益有效使用期必须大于0且小于等于权益包使用有效!');
          return false
        }
        if (item.availableCount && Number(item.availableCount) <= 0 && Number(item.availableCount) !== -1) {
          this.$message.error('权益可使用的次数必须大于0或者无限次!');
          return false
        }
        this.$emit("changeRight", item);
        this.$refs['dataForm'].resetFields()
        this.visible = false
      })
    }
  }
}
</script>
<style >
.red {
  color: red;
  font-size: 16px;
}
</style>
